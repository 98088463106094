body {
  /* background: white; */
}
.react-calendar {
  border-top: 0px solid rgb(0, 0, 0, 0.1);
  border-bottom: 0px solid black;
  border-left: 0px solid black;
  border-right: 0px solid rgb(0, 0, 0, 0.2);
  width: 100%;
  /* border-top-color: rgb(0 0 0 / 10%) 0px 0px 1px 1px !important; */
  background-color: #f2f2f2;
}
.react-calendar__viewContainer {
  background-color: white;
  border-radius: 12px;
  padding: 3%;
}

.react-calendar__tile--now {
  background-color: white;
}
.react-calendar__tile--active .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus .react-calendar__tile--active {
  background: #e97101 !important;
  color: white !important;
  border-radius: 8px;
  padding: 1% !important;
}
.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #e6e6e4;
  border-radius: 8px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e4;
  border-radius: 8px;
}

.react-calendar__tile--active {
  background: #e97101 !important;
  color: white !important;
  border-radius: 8px;
}
.react-calendar__navigation {
  /* border-bottom: 1px solid rgb(0, 0, 0, 0.1); */
  /* padding-bottom: 2%;
    padding-top: 2%; */
  height: 9vh;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 5%;
}
.react-calendar__navigation__label__labelText {
  font-weight: bold;
  font-size: 19px;
}

/* Responsive font sizes using media queries */
@media (max-width: 911px) {
  .react-calendar__navigation__label__labelText {
    font-size: 12px;
  }
}
@media (min-width: 912px) {
  .react-calendar__navigation__label__labelText {
    font-size: 19px;
  }
}
.react-calendar__navigation__arrow {
  font-weight: bold;
  font-size: 25px;
}
.react-calendar__navigation__label {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* This css code is for make selected date background color to cicrle. */
/* .react-calendar__tile--range abbr {
    padding: 10%;
    border-radius: 50%;
    background: #E97101;
} */

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

abbr[title] {
  text-decoration: none !important;
}
