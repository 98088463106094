
.category-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
  }
  
  .category-table thead tr {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
  }
  
  .category-table th, .category-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
  
  .category-table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  .category-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .category-table button {
    padding: 6px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .category-table button:hover {
    background-color: #45a049;
  }
  
  .category-table ul {
    list-style-type: none;
    padding: 0;
  }
  
  .category-table ul li {
    background: #f4f4f4;
    margin: 5px 0;
    padding: 5px;
    border-radius: 4px;
  }
  