.netural-white {
  color: #ffffff;
}

.netural-black {
  color: #1b1c1e;
}

.netural-grey {
  color: #545454;
}

.netural-orange {
  color: #ff8d24;
}

.go3842760039:before {
  background-color: #ff8d24 !important;
}

.go433304200:before {
  background-color: #ff8d24 !important;
}

.form-check-input:checked {
  background-color: #e97101 !important;
  border-color: #e97101 !important;
}

.input-group {
  flex-wrap: initial !important;
  align-items: center !important;
  border: 1px solid #dadada !important;
  border-radius: 12px;
  padding-left: 8px;
  padding-right: 8px;
  color: #ff8d24 !important;
}

.input-group > .form-control {
  border: none !important;
}

.form-check-input:disabled {
  opacity: 1 !important;
}

.form-check-input[disabled] ~ .form-check-label {
  opacity: 1 !important;
}

.PhoneInput {
  border: #dadada;
  border-width: 2px;
  border-style: solid;
  padding: 2%;
  height: 46px;
  border-radius: 12px;
}

.PhoneInputInput {
  border: none;
}

:focus-visible {
  outline: none;
  /* border-width: 0px; */
}

/* .height-100-per {
  height: 100% !important;
}

.width-100-per {
  width: 100% !important;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
} */

.Auth-form-container {
  /* max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto; */
  /* background-color: #FFFFFF; */
  border-radius: 25px;
}

.dashboard-header-shadow {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 1px 1px;
}

.icon-suffix {
  display: flex;
  align-items: center;
  margin-left: 10px;
  visibility: hidden;
}

.head-div.toggled {
  visibility: visible;
  margin-top: 15px;
  & .icon-suffix {
    visibility: visible;
  }
}

.Auth-form {
  box-shadow: rgb(0 0 0 / 16%) 10px 10px 10px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 24px;
  background-color: white;
}

.form-control {
  width: 100% !important;
  border-radius: 12px !important;
  /* height: 46px !important; */
  border: 1px solid #dadada !important;
}

.flag-dropdown {
  border-radius: 12px 0px 0px 12px !important;
  border: 1px solid #dadada !important;
}

.row {
  margin: 0% !important;
  padding: 0% !important;
}

.head-div {
  padding: 0px !important;
}

.sidebar-content,
.pro-menu {
  padding-top: 0% !important;
}

.main-div-right-section {
  background-color: #f2f2f2;
  width: 100%;
  overflow: auto;
  height: 100vh;
}

.second-section-style {
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 2%;
}

.modal-content {
  background-color: #ffffff !important;
  border-radius: 12px !important;
  /* padding-bottom: 2%; */
  border: 0px solid #dadada !important;
}

.searchWrapper {
  border: 1px solid #dadada !important;
  border-radius: 12px !important;
}

.highlightOption {
  background: #e97101 !important;
}

.multiSelectContainer li:hover {
  background: #dadada !important;
}
