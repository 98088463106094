.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.loading-spinner.small {
  width: 20px;
  height: 20px;
}

.loading-spinner.medium {
  width: 40px;
  height: 40px;
}

.loading-spinner.large {
  width: 60px;
  height: 60px;
}

.loading-spinner-text {
  margin-left: 10px;
  color: #FF8D24;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
